
import React, {useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "keen-slider/keen-slider.min.css"
import './styles.css';
import { useKeenSlider } from "keen-slider/react"
import MediumArticlePreview from '../components/MediumArticle.tsx';

interface Client {
    id: number;
    content: string;
    image: string;
    website: string;
    description: string;
    technologies?: string[];
    languages?: string[];
    sources?: string[];
}

const clients: Client[] = [
    {
        id: 1,
        content: "Chicago Fire",
        image: "chi-logo.png",
        website: "https://www.chicagofirefc.com/",
        description: "Data engineering solutions for Chicago Fire FC.",
        technologies:['snowflake', 'salesforce', 'ticketmaster', 'aws_s3', 'aws_lambda', 'aws_ec2', 'aws_cloudwatch', 'aws_cloudfront', 'aws_route53', 'postgres', 'tableau', 'sigma', 'okta', 'jira', 'github', 'prefect', 'openai'],
        languages: ['python', 'r', 'sql', 'javascript', 'react', 'dbt'],
    },
    {
        id: 2,
        content: "Boost Sports",
        image: "boost_logo.gif",
        website: "https://boostsport.ai/",
        description: "Sports data platform engineering and infrastructure.",
        technologies:['snowflake', 'aws_s3', 'aws_lambda', 'aws_ec2', 'aws_cloudwatch', 'jira', 'fivetran'],
        languages: ['python', 'sql', 'terraform', 'javascript', 'dbt'],
    },
    // {
    //     id: 3,
    //     content: "NYT Leaderboards",
    //     image: "leaderboard_logo.png",
    //     website: "https://www.nytleaderboards.com/",
    //     description: "Leaderboard website for NYT games using Django and AWS.",
    //     technologies:['django', 'aws_s3', 'aws_ec2', 'aws_cloudwatch', 'github', 'godaddy'],
    //     languages: ['python'],
    // },
    {
        id: 3,
        content: "Revenue Base",
        image: "revenuebase.png",
        website: "https://revenuebase.ai/",
        description: "Data Company specializing in Business Data",
        technologies:['snowflake'],
        languages: ['python', 'sql'],
    },
];

//from that list, combine into one list 
const allTechnologies: string[] = [];
const allLanguages: string[] = [];
const allSources: string[] = [];

clients.forEach(client => {
    allTechnologies.push(...(client.technologies || []));
    allLanguages.push(...(client.languages || []));
    allSources.push(...(client.sources || []));
});

//and deduplicate them 
const distinctTechnologies = [...new Set(allTechnologies)];
const distinctLanguages = [...new Set(allLanguages)];
const distinctSources = [...new Set(allSources)];

//randomize their order 
const techBubbles = [
    ...distinctTechnologies.map(tech => ({ type: 'technology', name: tech })),
    ...distinctLanguages.map(lang => ({ type: 'language', name: lang })),
    ...distinctSources.map(source => ({ type: 'source', name: source })),
].sort(() => Math.random() - 0.5);;

//value for the speed of the bubbles moving
const animation = { duration: 30000, easing: (t: number) => t }; // Animation settings


const PortfolioPage: React.FC = () => {

    const [sliderRefSmall] = useKeenSlider<HTMLDivElement>({
        loop: true,
        renderMode: "performance",
        mode: "free",
        drag: true,
        breakpoints: {
            "(min-width: 300px)": { slides: { perView: 2, spacing: .5 } },
            "(min-width: 400px)": { slides: { perView: 3, spacing: 1 } },
            "(min-width: 600px)": { slides: { perView: 5, spacing: 1 } },
            "(min-width: 1000px)": { slides: { perView: 8, spacing: 1 } },
            "(min-width: 1400px)": { slides: { perView: 10, spacing: 1 } },
        },
        slides: {
            origin: 'center',
            perView: 12,
            spacing: 1,
        },
        created(s) {
            s.moveToIdx(-15, true, animation); // Start auto-scroll on creation for small slider
        },
        updated(s) {
            s.moveToIdx(s.track.details.abs - 15, true, animation); // Continue auto-scroll on update
        },
        animationEnded(s) {
            s.moveToIdx(s.track.details.abs - 15, true, animation); // Loop the animation
        },
    });

    
    useEffect(() => {
        const script = document.createElement('script');
        script.src = '//cdn.credly.com/assets/utilities/embed.js';
        script.async = true;
        document.body.appendChild(script);
    }, []);


    return (
        <div className="container mt-3">
            {/* Portfolio Header */}
            <div className="text-center row">
                <h1 className="dashboard-title">Portfolio</h1>
                <p className="dashboard-description">Explore my work across data engineering, sports analytics, and tech consulting.</p>
            </div>

            {/* Featured Clients Section */}
            <section className="row mt-2">
                <h2 className="mb-4 text-center">Featured Clients</h2>
                {clients.map(client => {
                    const isGif = client.image.endsWith('.gif');
                    return (
                        <div key={client.id} className="col-md-4">
                            <a href={client.website} className="card p-3 text-center border bg-light mb-2 text-decoration-none">
                                <img src={client.image} alt={client.content} 
                                    className={isGif ? 'invert-gif' : 'card-img-top'}
                                 style={{ height: '150px', objectFit: 'contain' }} />
                                <div className="card-body">
                                    <h5 className="card-title">{client.content}</h5>
                                    <p className="card-text">{client.description}</p>
                                </div>
                            </a>
                        </div>
                    );
                })}
            </section>

            {/* Skills & Technologies */}
            <section className="container my-4">
                <h2 className="mb-4 text-center">Tools I've Used</h2>
                <div ref={sliderRefSmall} className="keen-slider tech-carousel">
                    {techBubbles.map((item, index) => (
                        <div className="keen-slider__slide tech-slide" key={index}>
                            <div className={`tech-circle ${item.type}`}>
                                <span></span>
                                <img src={`externals/${item.name}.png`}  alt={`${item.name} logo`} style={{ width: 'auto', height: '50px' }} />
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            {/* Project & Experience Details */}
            <section className="row g-4 mt-5">
                <h2 className="mb-4 text-center">Highlighted Projects & Experience</h2>

                {/* Chicago Fire Project */}
                <div className="col-md-6">
                    <div className="card p-3 border bg-light mb-4">
                        {/* Top Row: Image and Text */}
                        <div className="d-flex align-items-center">
                        <img 
                            src="chi-logo.png" 
                            alt="Chicago Fire FC" 
                            style={{ width: '90px', marginRight: '20px', borderRadius: '10px' }} 
                        />
                        <div>
                            <h3 style={{ margin: 0 }}>Full Stack Data Engineer</h3>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>Chicago Fire FC</p>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>Feb 2024 - Present</p>
                        </div>
                        </div>

                        {/* Bullet Points */}
                        <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                        <li>Built a front-office portal using React for the frontend and Django for the backend.</li>
                        <li>Developed a middleware API to connect the portal to Snowflake.</li>
                        <li>Served the portal using AWS CloudWatch, Route 53, S3, and an EC2 instance with Nginx for the backend.</li>
                        <li>Fine-tuned OpenAI models for email generation, article summarization, and text-to-SQL conversion.</li>
                        <li>Used Docker, Prefect, ECR, EC2, and Kubernetes for automated ingestion of all business-side club-related data.</li>
                        <li>Administered and managed data warehouse, data engineering, and application development initiatives.</li>
                        </ul>

                        {/* Related Medium Article */}
                        <div className="mt-4">
                        <MediumArticlePreview
                            title="Building a Sovereign Tech Stack in Sports"
                            link="https://medium.com/@seanpjk/building-a-sovereign-tech-stack-in-sports-f7b0f609e8fb"
                            description="This article goes more in depth on what solutions we use in our tech stack, why we went about choosing them, and some of the benefits we obtained in doing so. It is always an ongoing process, but this is how we arrived at the current state."
                            image="https://miro.medium.com/v2/resize:fit:1400/format:webp/1*b4Wq_gcjvdZXNJWeE6ksNg.png"
                        />
                        </div>
                    </div>
                </div>


                
                <div className="col-md-6">
                    {/* Revenue Base Project */}
                    <div className="card p-3 border bg-light mb-4">
                        <div className="d-flex align-items-center">
                        <img 
                            src="revenuebase_small.png" 
                            alt="Revenue Base" 
                            style={{ width: '80px', marginRight: '20px', borderRadius: '10px' }} 
                        />
                        <div>
                            <h3 style={{ margin: 0 }}>Engineering Consultant</h3>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>Revenue Base</p>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>October 2024 - Present</p>
                        </div>
                        </div>
                        <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                        <li>Developed data pipelines in Domo to create rich visualizations and dashboards to report on deal metrics, lead growth, renewal health.</li>
                        </ul>
                    </div>

                    {/* Boost Sports Project */}
                    <div className="card p-3 border bg-light mb-4">
                        <div className="d-flex align-items-center">
                        <img 
                            src="boost_logo.gif" 
                            alt="Boost Sports" 
                            style={{ width: '100px', marginRight: '20px', borderRadius: '10px', filter: 'invert(1)' }} 
                        />
                        <div>
                            <h3 style={{ margin: 0 }}>Engineering Consultant</h3>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>Boost Sports</p>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>May 2024 - November 2024</p>
                        </div>
                        </div>
                        <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                        <li>Engineered data pipelines and collaborated on front-end enhancements.</li>
                        <li>Developed dynamic end-to-end AWS, Snowflake, and Fivetran pipelines that streamline the flow of data from Client accounts to the central data warehouse.</li>
                        <li>Implemented Lambda functions for automated data cleansing to improve end-user experience.</li>
                        </ul>
                    </div>

                    {/* Dyer Brothers Sealcoating Project */}
                    <div className="card p-3 border bg-light mb-4">
                        <div className="d-flex align-items-center">
                        <img 
                            src="dyer_bros_no_bg.png" 
                            alt="Dyer Brothers Sealcoating" 
                            style={{ width: '100px', marginRight: '20px', borderRadius: '10px' }} 
                        />
                        <div>
                            <h3 style={{ margin: 0 }}>Engineering Consultant</h3>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>Dyer Brothers Sealcoating</p>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>July 2023 - February 2024</p>
                        </div>
                        </div>
                        <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                        <li>Migrated CRM to Hubspot, increasing efficiency of business processes and cleanliness of data.</li>
                        <li>Utilized AWS Pinpoint for cost-effective phone number analysis.</li>
                        </ul>
                    </div>
                </div>



                <div className="col-md-6">
                    <div style={{ position: 'relative', display: 'inline-block' }}>
                        {/* Red Stripe */}
                        <div
                        style={{
                            position: 'absolute',
                            top: '120px',
                            left: '0',
                            right: '0',
                            backgroundColor: 'rgba(255, 0, 0, 0.6)', // Semi-transparent red
                            color: 'white',
                            textAlign: 'center',
                            fontSize: '1.5rem',
                            fontWeight: 'bold',
                            padding: '10px 0',
                            transform: 'rotate(-10deg)', // Optional diagonal stripe effect
                            zIndex: 10,
                        }}
                        >
                        COMING SOON
                        </div>

                        <div className="card p-3 border bg-light mb-4">
                        {/* Top Row: Image and Headers */}
                        <div className="d-flex">
                            <img
                            src="handle_logo.png"
                            alt="Dyer Brothers Sealcoating"
                            style={{
                                width: '100px',
                                marginRight: '20px',
                                borderRadius: '10px',
                            }}
                            />
                            <div>
                            <h3 style={{ margin: 0 }}>Health Data App</h3>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>
                                tbd.com
                            </p>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>
                                November 2024 - Present
                            </p>
                            </div>
                        </div>

                        {/* Bullet Points */}
                        <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                            <li> Built a responsive web app using React and Express, hosted on AWS CloudFront.</li>
                            <li> Integrated Whoop login for seamless user authentication.</li>
                            <li> Implemented food tracking using nutritional data from{' '}<a href="https://world.openfoodfacts.org/" target="_blank" rel="noreferrer"> World Open Food Facts API</a>.</li>
                            <li> Enabled secure donations through Stripe, contributing to the{' '} <em>World Open Food Facts 2024 Fundraiser</em>. </li>
                            <li>
                            Developed an emotion-logging feature that syncs with users’ health
                            data, offering a comprehensive overview of daily activities.
                            </li>
                        </ul>
                        </div>
                    </div>
                </div>


                {/* NYT Leaderboards Project */}
                <div className="col-md-6">
                    <div className="card p-3 border bg-light mb-4">
                        {/* Top Row: Image and Text */}
                        <div className="d-flex align-items-center">
                        <img 
                            src="leaderboard_logo.png" 
                            alt="NYT Leaderboards" 
                            style={{ width: '100px', marginRight: '20px' }} 
                        />
                        <div>
                            <h3 style={{ margin: 0 }}>NYT Connections Leaderboard</h3>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>nytleaderboards.com</p>
                            <p style={{ margin: 0, fontSize: '0.9em', color: '#6c757d' }}>Feb 2024 - Present</p>
                        </div>
                        </div>

                        {/* Bullet Points */}
                        <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                        <li>Built a leaderboard site for NYT Connections scores using Django.</li>
                        <li>Implemented OAuth 2.0 and Google Analytics to improve user expereince.</li>
                        <li>Deployed on AWS EC2 with Nginx and Gunicorn.</li>
                        </ul>

                        {/* Related Medium Article */}
                        <div className="mt-4">
                        <MediumArticlePreview
                            title="How Staying Up All Night Coding Can Make Your Friends Happy"
                            link="https://medium.com/@seanpjk/how-staying-up-all-night-coding-can-make-your-friends-happy-a19ca0399c39"
                            description="This article tells the story of why we needed to make a leaderboard for our friends, and how we went about doing so."
                            image="https://miro.medium.com/v2/resize:fit:1400/format:webp/0*hYvkvGYEyZ3ySmoc"
                        />
                        </div>
                    </div>
                    </div>




            </section>
        </div>
    );
};

export default PortfolioPage;


